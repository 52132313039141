/** ALGOLIA SEARCH **/
body {
  #algolia-autocomplete-container {
    /* no need to display empty elements */
    .mpn-entry:empty {
      display: none;
    }
    .mpn-entry:not(:first-child):before {
      content: ", ";
    }
    .col9, .col3 {
      border-left: medium none;
      float: none;
      width: 100% !important;
    }
    .algolia-glass {
      display: block;
    }
    .aa-dropdown-menu {
      width: 100%;
      min-width: inherit !important;
      .category {
        padding: 8px 0;
        border: 1px solid #ccc;
        border-left: none;
        border-right: none;
        color: #043a70;
        text-align: left;
        text-align: center;
        font-size: .9em;
        text-transform: uppercase;
        background-color: #f8f8f8;
        font-weight: bold;
      }
      .aa-dataset-products {
        .algoliasearch-autocomplete-hit {
          margin-top: 0;
          padding-bottom: 5px;
        }
      }
    }
    .other-sections {
      margin: 0px 0 45px;
      .aa-suggestions {
        margin: 0px;
      }
    }
    .aa-dataset-products {
      .aa-suggestion {
        width: 100% !important;
        border-bottom: 1px solid #ccc;
        margin: 0 auto 0px;
      }
      .algoliasearch-autocomplete-hit {
        padding: 6px 10px;
        .info {
          a {
            color: black;
            &:hover, &:active {
              color: black;
            }
          }
        }
      }
      .algoliasearch-autocomplete-price {
        bottom: 0px;
        color: #043a70;
        float: left;
        font-size: 1.1em;
        height: 22px;
        position: relative;
        top: auto;
      }
      .other-sections {
        .aa-dataset-suggestions {
          display: none;
        }
      }
      .aa-suggestion {
        &:last-child {
          border-bottom: none;
        }
      }
    }
    .aa-dataset-products {
      .aa-suggestions {
        margin: 0 !important;
      }
    }
  }
  #autocomplete-products-footer {
    width: 100% !important;
    padding: 10px;
  }
  .ais-range-slider--value, .ais-range-slider--tooltip {
    font-size: 13px;
    font-weight: bold;
  }
  #algolia-searchbox {
    margin: 0;
    .algolia-search-input {
      float: none;
      width: 100%;
      max-width: inherit;
    }
    .search-input-text-qty {
      width: 50px !important;
      z-index: 9999;
    }
    .magnifying-glass {
      display: block !important;
      cursor: pointer;
    }
  }
  .search-custom-attr, .search-custom-attr2 {
    display: table;
    width: 85%;
  }
  @media only screen and (min-width: 768px) {
    .trigger-item--search {
      max-width: 100% !important;
    }
  }
  .info {
    .search-drawing {
      line-height: inherit;
      font-size: 10px;
      color: white !important;
      &:hover, &:active {
        color: white !important;
      }
    }
  }
}

@media only screen and (max-width: 771px) {
  body {
    #algolia-autocomplete-container {
      .aa-dropdown-menu {
        .other-sections {
          margin-bottom: 0px;
          .aa-suggestions {
            margin: 0px;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1152px) {
  .trigger-item--search, #algolia-autocomplete-container {
    position: initial !important;
  }
  .aa-dropdown-menu {
    top: inherit !important;
    width: 96.9% !important;
    margin-right: 24px;
  }
}

@media only screen and (max-width: 1079px) {
  .aa-dropdown-menu {
    width: 96.5% !important;
  }
}

@media only screen and (max-width: 960px) {
  .aa-dropdown-menu {
    width: 96.3% !important;
  }
}

@media only screen and (max-width: 887px) {
  .aa-dropdown-menu {
    width: 97% !important;
    margin-right: 16px;
  }
}

@media only screen and (max-width: 820px) {
  .aa-dropdown-menu {
    width: 96.7% !important;
    margin-right: 16px;
  }
}

@media only screen and (max-width: 768px) {
  .trigger-item--search {
    max-width: 100% !important;
  }
  #search {
    background: white;
    &:focus {
      background: white !important;
    }
  }
  .aa-dropdown-menu {
    width: 95.7% !important;
    margin: auto;
  }
}

#instant-search-results-container {
  .ratings, .price {
    display: none;
  }
  button {
    margin-top: 10px !important;
  }
}

.pdp-cart-form {
  .search-add-to-cart {
    position: absolute;
    right: 25px;
    top: 10px;
  }
}

/** END ALGOLIA SEARCH **/